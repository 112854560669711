import React, { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import queryString from 'query-string';
import { Flex, View, Text, Button } from '@instructure/ui';
import { isEmpty, join } from 'ramda';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import { camelize } from 'utils/keysConverter';
import Loader from 'components/IULoader';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import Flash from 'components/IUFlash';
import IURoleSelector from 'containers/PreApplication/sharedComponents/RoleSelector/components/IURoleSelector';
import IUPasswordFields from 'containers/PreApplication/sharedComponents/IUPasswordFields';

interface IUProfileSetupProps {
  errors: string[];
  user: Record<string, any>;
}

const IUProfileSetup: React.FC<IUProfileSetupProps> = ({ errors, user }) => {
  const location = useLocation();
  const parsedQueryString = useMemo(() => queryString.parse(location.search || ''), [location.search]);
  const redirect = useMemo(
    () => (parsedQueryString.redirect_back || '') + (location.hash || ''),
    [parsedQueryString, location.hash],
  );
  const [userFields, updateUserFields] = useState(camelize(user));
  const { isMobile, isTablet } = useResponsiveContext();

  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    updateUserFields({ ...userFields, [name]: e.target.value });
  };

  const handleChangeRole = (role: string) => {
    updateUserFields({ ...userFields, userType: userFields.userType !== role ? role : '' });
  };

  const padding = useMemo(() => (isMobile ? '10em' : '13em'), [isMobile]);

  const {
    memoTitle: title,
    memoDescription: description,
    memoFormFields: formFields,
    memoShowBackButton: showBackButton,
  } = useMemo(() => {
    let memoTitle: string | null = null;
    let memoDescription: string | null = null;
    let memoFormFields: React.ReactNode | null = null;
    let memoShowBackButton = false;
    const validUserType = userFields.userType && userFields.userType !== '';

    switch (userFields.signUpState) {
      case 'password_setup':
        memoTitle = 'Set Your Password';
        memoDescription =
          'Welcome back. To complete this process, type in a new password of your choice. The following criteria applies when selecting your new password:';
        memoFormFields = (
          <IUPasswordFields backToLogin={false} submitButton panelMinHeight="25em" buttonText="Set Password" />
        );
        break;
      case 'role_setup':
      case 'renew_role':
        memoTitle = !validUserType ? 'Select Your Role' : 'Registration';
        memoDescription = !validUserType
          ? 'Please select the role that best describes your responsibilities at your organization.'
          : 'Create your account';
        memoFormFields = (
          <IURoleSelector
            user={userFields}
            buttonText="Continue"
            onChange={handleChange}
            onChangeRole={handleChangeRole}
            disabled={!userFields.canChangeUserType}
          />
        );
        memoShowBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
        break;
      default:
        memoTitle = !validUserType ? 'Select Your Role' : 'Registration';
        memoDescription = !validUserType
          ? 'Please select the role that best describes your responsibilities at your organization.'
          : 'Create your account';
        memoFormFields = (
          <IURoleSelector
            showPassword
            user={userFields}
            buttonText="Continue"
            onChange={handleChange}
            onChangeRole={handleChangeRole}
            disabled={!userFields.canChangeUserType}
          />
        );
        memoShowBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
    }

    return { memoTitle, memoDescription, memoFormFields, memoShowBackButton };
  }, [userFields, handleChange, handleChangeRole]);

  if (!title || !description || !formFields) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showBackButton && <Button onClick={() => handleChangeRole('')}>Back</Button>}
      <form
        action={Routes.profile_path({ redirect_back: redirect, anchor: location.hash })}
        method="post"
        style={{ height: '100%' }}
      >
        <input type="hidden" name="authenticity_token" value={authenticityToken()} />
        <input type="hidden" name="_method" value="patch" />
        <Flex
          data-node="parent"
          as="div"
          direction="column"
          justifyItems="center"
          height={isTablet && !isMobile ? '100%' : `calc(100vh - ${padding})`}
        >
          <Flex.Item shouldShrink>
            <View as="div" margin="none">
              <Text weight="bold" size="x-large">
                {title}
              </Text>
              <View padding="large none none none" as="div">
                {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
                {description}
              </View>
            </View>
          </Flex.Item>
          {formFields}
        </Flex>
      </form>
    </>
  );
};

export default IUProfileSetup;
