import React, { useMemo, useState } from 'react';
import * as Routes from 'Routes';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import useFeatureFlags from 'hooks/useFeatureFlags';

import useDebouncedValue from 'hooks/useDebouncedValue';
import { PasswordFieldsProps } from '../RoleSelector/type';
import IUPasswordFields from '../IUPasswordFields';
import { PasswordSchema } from '../IUPasswordFields/passwordSchema';
import useStyles from './useStyles';

const MUPasswordFields = ({ backToLogin, buttonText, submitButton, className }: PasswordFieldsProps) => {
  const classes = useStyles();
  const [password, updatePassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');

  // Here use debounce will only return the new password value if it hasn't changed for 0.5 seconds, or any other timeout that you can provide as the second parameter for useDebouncedValue
  // Once the user stops typing and debouncedPassword is updated with the new password value, only then useMemo will run validation and return the updated password error message
  const debouncedPassword = useDebouncedValue(password);
  const validationMessage = useMemo(() => {
    try {
      PasswordSchema.validateSync(debouncedPassword);
      return '';
    } catch (err) {
      return (err as Error).message;
    }
  }, [debouncedPassword]);

  const isValidPassword = useMemo(() => validationMessage === '', [validationMessage]);

  return (
    <>
      <FormGroup className={className}>
        <TextField
          data-node="password"
          onChange={e => updatePassword(e.target.value)}
          name="user[password]"
          value={password}
          className={classes.textField}
          id="user_password"
          label="New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={!isValidPassword}
          helperText={!isValidPassword ? validationMessage : 'Required*'}
        />
        <TextField
          data-node="confirm_password"
          onChange={e => updateConfirmPassword(e.target.value)}
          name="user[password_confirmation]"
          value={confirmPassword}
          className={classes.textField}
          id="user_confirm_password"
          label="Confirm New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={!!confirmPassword && confirmPassword !== password}
          helperText={!!confirmPassword && confirmPassword !== password ? 'Passwords must match' : 'Required*'}
        />
        {backToLogin && (
          <div className={classes.backToLogin}>
            <Button variant="link" href={Routes.new_user_session_path()} className={classes.linkBackToLogin}>
              Back to Login
            </Button>
          </div>
        )}
      </FormGroup>
      {submitButton && (
        <Button
          data-node="password_button"
          type="submit"
          className={classes.button}
          disabled={!password || !confirmPassword || !isValidPassword || password !== confirmPassword}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

const PasswordFields = ({
  backToLogin = false,
  buttonText = 'Set Password',
  submitButton = true,
  className,
}: PasswordFieldsProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  return useNewLogin ? (
    <IUPasswordFields backToLogin={backToLogin} buttonText={buttonText} submitButton={submitButton} />
  ) : (
    <MUPasswordFields
      backToLogin={backToLogin}
      buttonText={buttonText}
      submitButton={submitButton}
      className={className}
    />
  );
};

export default PasswordFields;
